export const STUDIO_STEPS = {
  connect: 'connect',
  destinations: 'destinations',
  model: 'model',
  review: 'review',
  schedule: 'schedule',
  selectData: 'selectData',
};

export const STUDIO_NESTED_VIEWS = {
  modelDataset: 'modelDataset',
  scheduleDataset: 'scheduleDataset',
};

export const STUDIO_ONBOARDING_STEP_STATUSES = {
  completed: 'completed',
  pending: 'pending',
  started: 'started',
};

export const STUDIO_ROUTES = {
  [STUDIO_NESTED_VIEWS.modelDataset]: 'studio-data-products-dataProductId-model-datasets-datasetId',
  [STUDIO_NESTED_VIEWS.scheduleDataset]: 'studio-data-products-dataProductId-schedule-datasets-datasetId',
  [STUDIO_STEPS.connect]: 'studio-data-products-dataProductId-connect',
  [STUDIO_STEPS.destinations]: 'studio-data-products-dataProductId-destinations',
  [STUDIO_STEPS.model]: 'studio-data-products-dataProductId-model',
  [STUDIO_STEPS.review]: 'studio-data-products-dataProductId-review',
  [STUDIO_STEPS.schedule]: 'studio-data-products-dataProductId-schedule',
  [STUDIO_STEPS.selectData]: 'studio-data-products-dataProductId-select-data',
  studio: 'studio',
};
